import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbAccordionModule, NgbActiveModal, NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import { AgAccordion, AgPanelContent, AgPanelFooter, AgPanelHeader } from "./accordian/accordian.component";
import { BreadcrumbComponent } from "./breadcrumbs/breadcrumb.component";
import { BreadcrumbService } from "./breadcrumbs/breadcrumb.service";
import { AgModal, AgModalContent, AgModalFooter, AgModalHeader, AgModalTitle } from "./modal/ag-modal.component";
import { AgGridComponent, AgGridViewOptions } from './ag-grid/ag-grid.component';
import { AgGridModule } from 'ag-grid-angular';
import { AgOverlayContent, AgOverlayHeader, SideOverlayComponent } from './side-overlay/side-overlay.component';
import { NgSelectModule } from "@ng-select/ng-select";
import { AutofocusDirective } from "./directives/autofocus";
import { NgChartsModule } from 'ng2-charts';
import { ColumnChartComponent } from './column-chart/column-chart.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { CardTemplate, AgCardContent, AgLink } from './card-template/card-template.component';

@NgModule({
  declarations: [
    BreadcrumbComponent,
    AgAccordion,
    AgPanelHeader,
    AgPanelContent,
    AgPanelFooter,
    AgModalHeader,
    AgModalTitle,
    AgModalContent,
    AgModalFooter,
    AgModal,
    SideOverlayComponent,
    AgOverlayHeader,
    AgOverlayContent,
    AgGridViewOptions,
    AgGridComponent,
    AutofocusDirective,
    ColumnChartComponent,
    PieChartComponent,
    CardTemplate,
    AgCardContent,
    AgLink
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgbAccordionModule,
    NgbModalModule,
    NgbModalModule,
    AgGridModule,
    NgSelectModule,
    NgChartsModule
  ],
  exports: [
    BreadcrumbComponent,
    AgAccordion,
    AgPanelHeader,
    AgPanelContent,
    AgPanelFooter,
    AgModalHeader,
    AgModalTitle,
    AgModalContent,
    AgModalFooter,
    AgModal,
    SideOverlayComponent,
    AgOverlayHeader,
    AgOverlayContent,
    AgGridViewOptions,
    AgGridComponent,
    AutofocusDirective,
    NgChartsModule,
    ColumnChartComponent,
    PieChartComponent,
    CardTemplate,
    AgCardContent,
    AgLink
  ],
  providers: [
    BreadcrumbService,
    NgbActiveModal
  ]
})
export class UIModule { }
